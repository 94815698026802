import React, { Component } from 'react';
import { get, find } from 'lodash';
import styled, { withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Row, Col, Collapse, Icon } from 'antd';

import { Title, Paragraph } from '../common/components';

const { Panel } = Collapse;

import flowerIcon from '../assets/flower-thick-gold.svg';

const FaqItemsContainer = styled(Row)`
  position: relative;
  margin: 20px 30px 60px 30px;
  overflow: hidden !important;
`;

const ItemsCol = styled(Col)``;

const Accordion = styled(Collapse)`
  background-color: transparent !important;
  width: 100%;
`;

const ItemSection = styled(Panel)`
  .ant-collapse-header {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  border-bottom: 1px solid ${props => props.theme.color.primary.dark} !important;
`;

const ItemIcon = styled.img`
  height: 20px;
`;

const RightIcon = styled(Icon)`
  text-align: right;
  font-size: 16px !important;
  ${breakpoint('sm')`
    font-size: 22px !important;
  `}
  font-weight: bold !important;
  color: ${props => props.theme.color.primary.light} !important;
`;

const ItemTitle = styled(Title)`
  flex: 1;
  text-align: left;
  padding-left: 10px;
  margin-bottom: 0px !important;
`;

const ItemDescription = styled(Paragraph)`
  text-align: left;
  padding-left: 34px;
  padding-right: 30px;
`;

const HeaderContainer = styled.div`
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start;
`;

interface Props {
  items: any;
  theme: any;
}

interface State {
  openItems: string[];
}

class FaqItems extends Component<Props, State> {
  state = {
    openItems: [],
  };
  render() {
    const { theme, items } = this.props;

    return (
      <FaqItemsContainer>
        <ItemsCol xs={22} offset={1}>
          <Accordion
            bordered={false}
            expandIcon={() => <ItemIcon src={flowerIcon} />}
            onChange={items => this.setState({ openItems: items })}
            accordion
          >
            {items.map((item, idx) => {
              const itemTitle = get(item, 'item.document[0].data.title', '');
              const itemDescription = get(
                item,
                'item.document[0].data.description.html',
                ''
              );
              return (
                <ItemSection
                  header={
                    <HeaderContainer>
                      <ItemTitle level={3} color={theme.color.text.main}>
                        {itemTitle}
                      </ItemTitle>
                      <RightIcon
                        type={
                          find(
                            this.state.openItems,
                            item => item === String(idx)
                          )
                            ? 'up'
                            : 'down'
                        }
                      />
                    </HeaderContainer>
                  }
                  key={idx}
                >
                  <ItemDescription small>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: itemDescription,
                      }}
                    />
                  </ItemDescription>
                </ItemSection>
              );
            })}
          </Accordion>
        </ItemsCol>
        <ItemsCol xs={1} />
      </FaqItemsContainer>
    );
  }
}

export default withTheme(FaqItems);
