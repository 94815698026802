import { graphql } from 'gatsby';

import React, { Component } from 'react';
import { get, find } from 'lodash';

import Layout from '../layout/Layout';

import { PageHero, SectionTitle } from '../common/components';
import FaqItems from './FaqItems';

interface Props {
  data: any;
  location: any;
}

class FAQ extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const body = get(data, 'page.data.body');
    const faqTitle = get(data, 'page.data.title');
    const faqHeroData = find(body, { __typename: 'PrismicFaqBodyHero' });
    const faqItemsData = find(body, { __typename: 'PrismicFaqBodyFaqItems' });
    const faqItems = get(faqItemsData, 'items', null);

    return (
      <Layout data={data} location={this.props.location}>
        {faqHeroData && <PageHero data={faqHeroData} />}
        {faqTitle && <SectionTitle title={faqTitle} />}
        {faqItems && <FaqItems items={faqItems} />}
        <div style={{ width: '100%', height: '3rem' }} />
      </Layout>
    );
  }
}

export default FAQ;

// Query metadata for the homepage
export const query = graphql`
  query FaqQuery($slug: String!) {
    page: prismicFaq(id: { eq: $slug }) {
      ...Faq
    }
  }
`;
